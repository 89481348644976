import { computed, ref, watch } from 'vue'
import { type RouteConfig } from 'vue-router'
import { Access } from '/~/types/access'
import { type ApiResponseList } from '/~/types/api'
import api from '/~/core/api'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { useFraudAndAmlScreening } from '/~/extensions/fraud-and-aml-screening/composables/use-fraud-and-aml-screening'
import { useOtp } from '/~/extensions/otp/composables'
import { useKyc } from '/~/extensions/user-kyc/composables/use-kyc'
import { useUser } from '/~/composables/user'
import router from '/~/router'

const { user, fetchUserDetails } = useUser()

const isCheckingIdentityVerification = ref(false)

export const hasUnfinishedVerifications = computed(() => {
  const { isEnrolmentOtpEnabled } = useOtp()
  const { isKycEnabled } = useKyc()

  return (
    (isEnrolmentOtpEnabled.value && user.value.hasUnfinishedOtpVerifications) ||
    (isKycEnabled.value && user.value.isAwaitingIdentityVerification)
  )
})

const isAwaitingFraudAndAmlScreening = computed(() => {
  const { isFraudAndAmlScreeningEnabled } = useFraudAndAmlScreening()

  return (
    isFraudAndAmlScreeningEnabled.value &&
    user.value.isAwaitingFraudAndAmlScreening
  )
})

const isAccessRestricted = computed(() => {
  return (
    hasUnfinishedVerifications.value || isAwaitingFraudAndAmlScreening.value
  )
})

const channel = new BroadcastChannel('access')

channel.onmessage = (e) => {
  const { key, value } = e.data

  if (key === 'isAccessRestricted') {
    if (value !== isAccessRestricted.value) {
      fetchUserDetails()
    }
  }
}

watch(isAccessRestricted, (value) => {
  channel.postMessage({
    key: 'isAccessRestricted',
    value,
  })
})

async function showAccessRestrictedModal(): Promise<unknown> {
  if (hasUnfinishedVerifications.value) {
    return showVerificationWarningModal()
  } else {
    return showFraudAndAmlScreeningWarningModal()
  }
}

function showVerificationWarningModal() {
  return new Promise((resolve, reject) => {
    const modalController = ui.mobile ? bottomSheet : modal

    modalController.show('verification-warning', {
      on: {
        hide: () => {
          reject()
        },
        close: () => {
          reject()
        },
      },
      props: {
        onSubmit: () => {
          router.push({ hash: '#profile-verification' })
          resolve()
        },
      },
    })
  })
}

function showFraudAndAmlScreeningWarningModal() {
  return new Promise((resolve, reject) => {
    const modalController = ui.mobile ? bottomSheet : modal

    modalController.show('fraud-and-aml-screening-warning', {
      on: {
        hide: () => {
          reject()
        },
        close: () => {
          reject()
        },
      },
    })
  })
}

async function withAccessCheck({
  callback,
  beforeShowModal,
  reject,
  config = {},
}: {
  callback?: () => void
  beforeShowModal?: () => void | Promise<void>
  reject?: () => void
  config?: Access.Check.Config
}): Promise<boolean> {
  const { isKycEnabled } = useKyc()

  if (isKycEnabled.value && isKycTriggerCheck(config)) {
    const isIdentityVerified = await checkIdentityVerification()

    if (!isIdentityVerified) {
      try {
        await beforeShowModal?.()
        await showVerificationWarningModal()
      } finally {
        reject?.()
      }

      return false
    }
  }

  if (!isAccessRestricted.value) {
    callback?.()
    return true
  } else {
    try {
      await beforeShowModal?.()
      await showAccessRestrictedModal()
    } finally {
      reject?.()
    }

    return false
  }
}

async function checkIdentityVerification() {
  isCheckingIdentityVerification.value = true

  try {
    const { data } = await api.get<ApiResponseList<Kyc.IdentityVerification>>(
      '/v3/identity-verifications',
      {
        query: {
          status: 'verified',
        },
      }
    )

    return data?.items?.length > 0
  } catch (error) {
    console.error(
      'There was an error updating your verification status:',
      error
    )
    return false
  } finally {
    isCheckingIdentityVerification.value = false
  }
}

function isKycTriggerCheck(config: Access.Check.Config): boolean {
  if (!config.actionType) {
    return false
  }

  const {
    isPaymentMethodsAddKycRequired,
    isPayeesAddKycRequired,
    isPaymentMethodCheckoutKycRequired,
    isPaymentMethodsSelectKycRequired,
  } = useKyc()

  switch (config.actionType) {
    case 'payees':
      return isPayeesAddKycRequired.value
    case 'paymentMethods':
      return isPaymentMethodsAddKycRequired.value
    case 'paymentMethodsCheckout': {
      return isPaymentMethodCheckoutKycRequired.value
    }
    case 'paymentMethodsSelect': {
      if (config.paymentMethodType !== undefined) {
        return isPaymentMethodsSelectKycRequired(config.paymentMethodType)
      }

      return false
    }
    default:
      return false
  }
}

export function getAccessGuard(
  config: Access.Check.Config = {}
): RouteConfig['beforeEnter'] {
  return async (_, __, next) => {
    await withAccessCheck({
      callback: () => {
        next()
      },
      beforeShowModal: () => {
        next({ name: 'home' })
      },
      config,
    })
  }
}

emitter.on('access:restricted', () => {
  showAccessRestrictedModal()
})

export function useAccess() {
  return {
    hasUnfinishedVerifications,
    isAwaitingFraudAndAmlScreening,
    isAccessRestricted,
    withAccessCheck,
    isCheckingIdentityVerification,
    showAccessRestrictedModal,
  }
}
